import create, { StateCreator } from 'zustand';
import { persist, PersistOptions } from 'zustand/middleware';

export type LocalBasketState = {
    setMembership: (state: boolean) => void;
    membership: boolean;
};

type LocalBasketTypePersist = (
    config: StateCreator<LocalBasketState>,
    options: PersistOptions<LocalBasketState>
) => StateCreator<LocalBasketState>;

export const useLocalBasketState = create<LocalBasketState>(
    (persist as LocalBasketTypePersist)(
        (set) => ({
            membership: false,
            setMembership: (state) => set({ membership: state }),
        }),
        {
            name: 'basket-state',
        }
    )
);
