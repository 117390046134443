import { useRouter } from 'next/router';
import React, { FC } from 'react';
import { useFrame } from '~/shared/utils';
import { usePage } from '~/templates/pages/hooks/usePage';

import { useTranslation } from '~/shared/utils/translation';
import { theme } from '~/theme';
import useProducts from '../../../commerce-api/hooks/useProducts';
import { StyledBasketHeader, StyledButtonContinueShopping } from './styled';

export const BasketHeader: FC = () => {
    const router = useRouter();
    const { translate } = useTranslation();

    const {
        data: { settings },
    } = useFrame();
    const page = usePage();

    const { handlers } = useProducts();

    const storage = globalThis?.sessionStorage;
    const prevPath = storage?.getItem('prevPath');

    const goBack = () => {
        const { hostname } = window.location;
        if (
            prevPath !== settings?.pageReferences?.checkoutPage?.url &&
            prevPath !== router.asPath &&
            prevPath?.includes(hostname)
        ) {
            router.back();
        } else {
            router.push('/');
        }
    };

    const hasFreeShipping = handlers.getHasFreeShipping();
    const amountToFreeShipping = handlers.getAmountToFreeShipping();

    return (
        <StyledBasketHeader
            freeShipping={hasFreeShipping}
            textColor={page?.announcementBar?.textColor}
            backgroundColor={page?.announcementBar?.backgroundColor}
            textAlign={page?.announcementBar?.textAlignment}
        >
            {hasFreeShipping ? (
                translate('basket.basket.freeShipping.summary')
            ) : (
                <>
                    {amountToFreeShipping &&
                        translate('basket.basket.forFreeShipping', {
                            amount: String(handlers.getAmountToFreeShipping()),
                        })}

                    <StyledButtonContinueShopping
                        color={theme.colors.black}
                        title={translate('basket.basket.goToCheckout')}
                        aria-label={translate('basket.backButton')}
                        onClick={goBack}
                    >
                        {translate('basket.continueShopping')}
                    </StyledButtonContinueShopping>
                </>
            )}
        </StyledBasketHeader>
    );
};
