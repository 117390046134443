import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { useBasket } from '~/features/basket';
import { Modules, P20FrontPage as P20FrontPageType } from '~/lib';
import { ProductItemBasket } from '~/lib/data-contract/salesforce';
import { useTranslation } from '~/shared/utils/translation';
import { DynamicBlockList } from '~/templates/blocks';
import useCustomer from '~/features/commerce-api/hooks/useCustomer';
import { notifyError, notifyInfo } from '~/shared/utils/errorBoundary/toast';
import { useLocalBasketState } from '~/features/basket/hooks/useLocalBasketState';

export const P20FrontPage = (props: P20FrontPageType) => {
    const { pageElements, bottomPageElements } = props;
    const router = useRouter();
    const basket = useBasket();
    const { translate } = useTranslation();
    const { isInitialized, isGuest } = useCustomer();
    const { membership } = useLocalBasketState();

    const parseDecodedString = (str: string): ProductItemBasket[] => {
        return str.split(';').map((item) => {
            const [quantity, id] = item.split(',');
            return { quantity: Number(quantity), productId: id };
        });
    };

    const basketAddItemFromIpaper = async (ipaperData: ProductItemBasket[]) => {
        try {
            const result = await basket.baseAddItemToBasket(ipaperData);

            if (result) {
                notifyInfo(translate('basket.addToBasketSuccessFromCatalogues'));
                router.replace('/');
            }
        } catch (error) {
            notifyError(new Error(translate('basket.addToBasketErrorFromCatalogues')));
            console.error(
                'No item added to basket due to items being out of stock, invalid, or unknnown',
                error,
            );
            router.replace('/');
        }
    };

    useEffect(() => {
        const processUrlParams = async () => {
            // Wait until the router is ready
            if (!router.isReady) return;

            const encodedData = router.query.data as string;

            if (encodedData) {
                try {
                    const decoded = atob(encodedData);
                    const parsedData = parseDecodedString(decoded);

                    // Wait for the basket item to be added
                    await basketAddItemFromIpaper(parsedData);
                } catch (error) {
                    console.error('Error decoding or parsing data:', error);
                }
            }
        };

        // Only run when router and basket is actual ready
        if (router.isReady && basket.loaded) {
            processUrlParams();
        }
    }, [
        router.isReady,
        router.query.data,
        basket.loaded,
        isInitialized,
        isGuest,
        membership,
        translate,
    ]);

    return (
        <>
            <DynamicBlockList elements={pageElements as Modules[]} />
            <DynamicBlockList elements={bottomPageElements as Modules[]} />
        </>
    );
};
